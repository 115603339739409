module.exports = ({ NPM_NODE_CLIENT, URL_CLIENT }) => `// Maven
<dependency>
<groupId>com.solidstategroup</groupId>
<artifactId>bullet-train-client</artifactId>
<version>1.2</version>
</dependency>

// Gradle
implementation 'com.solidstategroup:bullet-train-client:1.2'
`;
